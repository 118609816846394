import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';

const BASE = '/Campaign';

export async function affiliate_req(token, clickid, zoneid) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/Affiliate`, {
      params: {
        token,
        clickid,
        zoneid,
      },
    })
  );
}
export async function phx_req(id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/PhoenixTracker?pxClickID=${id}`,
      getReqConfig({})
    )
  );
}

export async function teaseTracker_req({ partner_id, widget_id, teaser_id }) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/TeaseTracker?partner_id=${partner_id}&widget_id=${widget_id}&teaser_id=${teaser_id}`
    )
  );
}

export async function explore_ads_req(id, source_id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/ExploreAdsTracker?expClickID=${id}&expSourceID=${source_id}`,
      getReqConfig({})
    )
  );
}

export async function hyros_ads_req(id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/HyrosTracker?hyClickID=${id}`,
      getReqConfig({})
    )
  );
}

export async function advertise_net_req(id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/AdvertiseNetTracker?advClickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function ppc_req(id) {
  return parseResponse(
    axiosInstanceV1.get(`${BASE}/PpcTracker?ClickID=${id}`, getReqConfig({}))
  );
}
// export async function nexico_ads_req(id, zone_id) {
//   return parseResponse(
//     axiosInstanceV1.get(
//       `${BASE}/NexicoAdsTracker?clickID=${id}&zoneid=${zone_id}`,
//       getReqConfig({})
//     )
//   );
// }
export async function nexico_ads_req(affID, id, zone_id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/NexicoAdsTracker?affId=${affID}&clickID=${id}&zoneid=${zone_id}`,
      getReqConfig({})
    )
  );
}
export async function ytz_ads_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/YtzAdsTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function eskimi_ads_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/EskimiAdsTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}

export async function dsp_ads_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/DSPartnersAdsTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function ad_crash_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/AdCashTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function rockit_ad_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/RockitAdsTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function ramp_ads_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/RampAdsTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function cinred_ads_req(affID, id) {
  return parseResponse(
    axiosInstanceV1.get(
      `${BASE}/CinredTracker?affId=${affID}&clickID=${id}`,
      getReqConfig({})
    )
  );
}
export async function getAffiliate_req() {
  return parseResponse(axiosInstanceV1.get('/GetAffiliate', getReqConfig({})));
}
